import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withFirebase } from '../Firebase'
import Tabs from '../Tabs'
import ProfileTab from './ProfileTab'
import PasswordChange from '../PasswordChange'

const tabList = [
  {
    name: 'Dashboard',
  },
  {
    name: 'Profile',
  },
  {
    name: 'Service Providers',
  },
  {
    name: 'Leads',
  },
  {
    name: 'Settings',
  },
]

const Profile = props => {
  const [activeTab, setActiveTab] = useState('Profile')
  const { authUser } = props

  console.log(authUser)

  const changeActiveTab = tab => {
    setActiveTab(tab)
  }

  const activeTabContent = () => {
    const activeIndex = tabList.findIndex(tab => {
      return tab.name === activeTab
    })
    return tabList[activeIndex].name
  }

  const ActiveTabContent = props => {
    if (props.content === 'Profile') {
      return ProfileTab()
    }
    return <div>{props.content}</div>
  }

  return (
    <div>
      <h1 className="has-text-white">My Profile</h1>
      <div className="box">
        <div className="section">
          <div className="columns">
            <div className="column is-4">
              <div className="block">
                <figure className="image is-256x256">
                  <img
                    className="is-rounded"
                    src="https://bulma.io/images/placeholders/256x256.png"
                    alt="Jane Doe"
                  />
                </figure>
                <h1>Jane Doe</h1>
              </div>
              <div className="block">
                <p>Your profile is 80% complete</p>
                <progress className="progress is-info" value="80" max="100">
                  80%
                </progress>
                <p>
                  You need to complete your prole in order to enjoy the 99 Sense
                  benefits
                </p>
              </div>
              <div className="block">
                <h3>Change Password</h3>
                <PasswordChange />
              </div>
            </div>
            <div className="column">
              <Tabs
                tabList={tabList}
                activeTab={activeTab}
                changeActiveTab={tab => changeActiveTab(tab)}
              />
              <ActiveTabContent key={activeTab} content={activeTabContent()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Profile.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
  }),
}

export default withFirebase(Profile)
