import React from 'react'
import { compose } from 'recompose'

import Layout from '../../components/Layout'
import Profile from '../../components/Profile'

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../../components/Session'

const MyProfilePageBase = ({ title }) => {
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <section className="section">
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                  {title}
                </h1>
                <Profile authUser={authUser} />
              </div>
            </div>
          </div>
        </section>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser

const MyProfilePage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(MyProfilePageBase)

const MyProfile = () => {
  return (
    <Layout>
      <section className="section section-gradient">
        <div className="container">
          <div className="content">
            <MyProfilePage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MyProfile
