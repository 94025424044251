import React from 'react'

const Tab = props => {
  const { name } = props.tab
  const { activeTab, changeActiveTab } = props

  return (
    <li
      className={name === activeTab && 'is-active'}
      onClick={() => changeActiveTab(name)}
      onKeyDown={() => changeActiveTab(name)}
      role="presentation"
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        <span>{name}</span>
      </a>
    </li>
  )
}

export default Tab
