import React from 'react'
import PropTypes from 'prop-types'

import Tab from './Tab'

const Tabs = props => {
  const { activeTab, changeActiveTab, tabList } = props

  return (
    <div className="tabs is-right">
      <ul>
        {tabList.map(tab => (
          <Tab
            tab={tab}
            key={tab.name}
            activeTab={activeTab}
            changeActiveTab={changeActiveTab}
          />
        ))}
      </ul>
    </div>
  )
}

Tabs.propTypes = {
  activeTab: PropTypes.string,
  changeActiveTab: PropTypes.func,
  tabList: PropTypes.array,
}

export default Tabs
