import React from 'react'

const FileUpload = props => {
  const onChange = event => {
    props.uploadHandler({ [event.target.name]: event.target.files[0].name })
  }

  return (
    <div className="field">
      <div className="file">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="attachment"
            onChange={onChange}
          />
          <span className="file-cta">
            <span className="file-label">{props.text}</span>
          </span>
        </label>
      </div>
    </div>
  )
}

export default FileUpload
