import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withFirebase } from '../Firebase'
import FileUpload from '../FileUpload'

const ProfileTab = props => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    workNumber: '',
    address: '',
  })
  const [error, setError] = useState({ message: '' })
  const { authUser } = props
  const formInvalid =
    formData.firstName === '' ||
    formData.lastName === '' ||
    formData.email === '' ||
    formData.mobileNumber === '' ||
    formData.workNumber === '' ||
    formData.address === ''

  const onSubmit = () => {
    props.firebase
      .createProfileTab({
        ...formData,
        creatorId: authUser.uid,
      })
      .then(() => {
        resetForm()
      })
      .catch(error => {
        console.log(error)
        setError(error)
      })
  }

  const onChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      workNumber: '',
      address: '',
    })
  }

  const onUploadDocument = () => {}

  return (
    <div className="box">
      <div className="section">
        <form>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label" htmlFor={'firstName'}>
                  First Name
                </label>
                <div className="control">
                  <input
                    className="input"
                    name="firstName"
                    value={formData.firstName}
                    onChange={onChange}
                    type="text"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  ID / Passport Number
                </label>
                <div className="control">
                  <input
                    className="input"
                    name="email"
                    value={formData.email}
                    onChange={onChange}
                    type="text"
                    placeholder="ID / Passport Number"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'workNumber'}>
                  Work Number
                </label>
                <div className="control">
                  <input
                    className="input"
                    name="workNumber"
                    value={formData.workNumber}
                    onChange={onChange}
                    type="text"
                    placeholder="Work Number"
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label" htmlFor={'lastName'}>
                  Last Name
                </label>
                <div className="control">
                  <input
                    className="input"
                    name="lastName"
                    value={formData.lastName}
                    onChange={onChange}
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'mobileNumber'}>
                  Mobile Number
                </label>
                <div className="control">
                  <input
                    className="input"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={onChange}
                    type="text"
                    placeholder="Mobile Number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label" htmlFor={'address'}>
                  Address
                </label>
                <div className="control">
                  <input
                    className="input"
                    name="address"
                    value={formData.address}
                    onChange={onChange}
                    type="text"
                    placeholder="Address"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field has-text-centered">
            <button
              className="button is-link is-rounded is-medium"
              type="button"
              onClick={onSubmit}
              disabled={formInvalid}
            >
              Save
            </button>
          </div>
          {error && <p>{error.message}</p>}
        </form>
      </div>
      <div className="block">
        <h3>About</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div className="block">
        <h3>Uploads</h3>
        <FileUpload text="Add New Document" uploadHandler={onUploadDocument} />
      </div>
    </div>
  )
}

ProfileTab.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
  }),
}

export default withFirebase(ProfileTab)
